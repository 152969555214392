import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/Restaurants.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import recoger from '../img/ICONOS GUMIT/50.png'
import pedir from '../img/ICONOS GUMIT/51.png'
import whatsapp from '../img/ICONOS GUMIT/48.png'

import logo from '../img/wine-svgrepo-com.svg';

const Restaurants = () => {
  const [restaurantList, setRestaurantsList] = useState([]);
  const [filteredRestaurants, setFilteredRestaurants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isTakeAway, setIsTakeAway] = useState(false);
  const [isDelivery, setIsDelivery] = useState(false);
  const [isWhatsappOrder, setIsWhatsappOrder] = useState(false);

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await axios.get('https://back.gumithuesca.com/management/restaurant/elastic?name=*');
        setRestaurantsList(response.data.restaurantSearchList);
        setFilteredRestaurants(response.data.restaurantSearchList);
      } catch (error) {
        console.error('Error fetching restaurantList:', error);
      }
    };

    fetchRestaurants();
  }, []);

  useEffect(() => {
    const results = restaurantList.filter(restaurant =>
      restaurant.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!isTakeAway || (isTakeAway && restaurant.takeAway) ) 
      &&
      ( !isDelivery || (isDelivery && restaurant.isdelivery))
      &&
      ( !isWhatsappOrder || (isWhatsappOrder && restaurant.whatsappOrder) )

    );
    setFilteredRestaurants(results);
  }, [searchTerm, isTakeAway, isDelivery, isWhatsappOrder, restaurantList]);

  return (
    <div className="container">
      
      <input
        type="text"
        placeholder="Buscar restaurante..."
        className="form-control mb-3"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />



      <ul className="list-flex">
        {filteredRestaurants.map((restaurant) => (
          <Link to={`/carta/${restaurant.id}`} className="admin-menu-item-first">  
            <li key={restaurant.id} className="restaurant-item">
              <div className="card" style={{ marginBottom: '20px', transition: 'transform 0.3s ease-in-out' }}>
                <img src={restaurant.logo == null ? logo : restaurant.logo} className="card-img-top" alt={`Imagen de ${restaurant.name}`} />
                <div className="card-body-custom" style={{ background: '#d4aa8a', boxShadow: 'initial' }}>
                  <h5 className="card-title" style={{ padding: '10px', color: 'white' }}>{restaurant.name}</h5>
                  <p className="card-text" style={{ color: 'white' }}>{restaurant.description}</p>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default Restaurants;
